class ApiService {
  static showLoginForm = (url) => {
    console.log(`Logging in for ${url}`);
  };
  static hideLoginForm = () => {};

  static extractJwtUrl() {
    const hash = window.location.hash;
    const prefix = "#jwt=";
    if (hash.startsWith(prefix)) {
      const jwt = hash.replace(prefix, "");
      // const parsed = this.parseJwt(jwt);
      // localStorage.setItem(`_JWT-${parsed.aud.replace("api-", "")}`, jwt);
      localStorage.setItem(`_JWT`, jwt);
      window.location.hash = "";
      window.close();
    }
    // alert('extracted');
  }

  static parseJwt(data) {
    data = data || localStorage.getItem(`_JWT`);
    return JSON.parse(atob(data.split(".")[1]));
  }

  static _getJwt() {
    const key = `_JWT`;
    const jwt = localStorage.getItem(key);
    if (!jwt) return false;

    try {
      const parsed = this.parseJwt(jwt);
      if (parsed.exp < Date.now() / 1000) throw "Expired";
      return jwt;
    } catch (e) {
      return false;
    }
  }

  static async logout(){
    const key = `_JWT`;
    localStorage.removeItem(key);
  }

  static async requestToken(service) {
    while (!this._getJwt(service)) {
      // console.log("check");
      this.showLoginForm(
        `https://membri.poliradio.it/login.php?api=generate_JWT&aud=api-${service}`
      );
      await this.sleep(1000);
    }
    const jwt = this._getJwt(service);
    if (!jwt) {
      alert("Internal Error, Please Reload");
    } else {
      this.hideLoginForm();
      return jwt;
    }
  }

  static sleep(t) {
    return new Promise((f) => setTimeout(f, t));
  }

  static async send(service, action, data) {
    try {
      const jwt = await this.requestToken(service);
      let sendData = new FormData();
      Object.keys(data)
        .filter((item) => item)
        .forEach((key) => sendData.append(key, data[key]));
      sendData.append("_JWT", jwt);
      const response = await fetch(
        `https://membri.poliradio.it/api/${service}/index.php?action=${action}`,
        {
          method: "POST",
          body: sendData,
        }
      );

      switch (response.status) {
        case 403:
          alert("Permission Denied");
          break;
        case 200:
          return await response.json();
        default:
          throw `Unhandled Code ${response.status}`;
      }
    } catch (e) {
      console.error(e);
      alert("Internal Error, please reload the page");
    }
  }
}

export default ApiService;
