<template>
  <div>
    <!-- <div style="margin-top: 250px">{{filteredEvents}}</div> -->
    <!-- <v-dialog
      ref="date-dialog"
      v-model="selectDateOpen"
      
      width="290px"
    >
      <v-date-picker v-model="selectedDate" scrollable>

      </v-date-picker>
    </v-dialog> -->
    <v-dialog ref="event-dialog" v-model="updateEventOpen" width="290px">
      <!-- <v-btn icon class="ma-2" @click="closeEventInfo()">
            <v-icon>mdi-close-thick</v-icon>
          </v-btn> -->
      <event-editor
        :id="selectedEvent.id"
        :title="selectedEvent.title"
        :start="selectedEvent.start"
        :color="selectedEvent.color"
        :room="selectedEvent.resource"
        :end="selectedEvent.end"
      />
    </v-dialog>
    <v-app-bar fixed>
      <!-- {{newEventDate}} -->
      <v-btn icon class="ma-2" @click="loadPrevWeek()">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <!-- <v-btn icon class="ma-2" @click="openEventInfo()">
        <v-icon>mdi-plus-thick</v-icon>
      </v-btn> -->
      <!-- <v-btn icon class="ma-2" @click="refreshData()">
        <v-icon>mdi-refresh</v-icon> </v-btn
      ><v-progress-circular
        indeterminate
        color="primary"
        v-if="loading"
      ></v-progress-circular
      > -->
      <v-chip
        v-for="room in rooms"
        :key="room.value"
        class="ma-2"
        :color="'rgb(' + room.color + ')'"
        :outlined="!room.selected"
        @click="() => enableRoom(room.value)"
        >{{ room.text }} </v-chip
      ><v-spacer></v-spacer>
      <!-- <v-btn text color="primary" @click="openSelectDateModal">
        {{ selectedDate }}
      </v-btn> -->
      {{ selectedTimestamp }} -
      {{ curLocaleDate }}
      <v-btn @click="logout">
        <v-icon>LOGOUT</v-icon>
      </v-btn>
      <v-btn icon class="ma-2" @click="loadNextWeek()">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <!-- <v-select
            :items="availableRooms"
            v-model="selectedRoom"
          ></v-select> -->
    </v-app-bar>
    <v-sheet style="padding-top: 100px">
      <v-col>
        <v-row> </v-row>
        <v-row>
          <v-calendar
            :start="calendarStart"
            :end="calendarEnd"
            ref="calendar"
            type="custom-daily"
            :events="filteredEvents"
            event-overlap-mode="stack"
            :event-overlap-threshold="30"
            :show-month-on-first="true"
            @click:event="openEventInfo"
            first-time="07:00"
            interval-count="18"
            style="width: 100%"
            @mousemove:time="createEventPlaceholder"
          ></v-calendar>
          <!-- @click:time="createEvent" -->
        </v-row></v-col
      >
    </v-sheet>
  </div>
</template>
<script>
import ApiService from "../api.service.class";
import EventEditor from "./EventEditor.vue";

// import DatetimeSelector from "./DatetimeSelector.vue";
export default {
  components: { EventEditor },
  // components: { DatetimeSelector },
  name: "Calendar",
  data: () => ({
    type: "",
    newEventDate: "sss",
    rooms: [
      {
        text: "Studio1",
        value: "room-studio1",
        selected: true,
        color: "0,0,255",
      },
      {
        text: "Studio2",
        value: "room-studio2",
        selected: true,
        color: "255,0,0",
      },
      {
        text: "Palinsesto",
        value: "room-palinsesto",
        selected: true,
        color: "47,209,6",
      },
      // {
      //   text: "PILLS",
      //   value: "room-pills",
      //   selected: true,
      //   color: "244,149,151",
      // },
    ],
    palinsesto: [],
    selectedTimestamp: 0,
    // selectedDate: `${new Date().toISOString().split("T")[0]}`,
    selectedEvent: {},
    updateEventOpen: false,
    selectedRooms: [],
    events: [],
    eventPlaceholders: [],
    loading: false,
    refreshInterval: null,
  }),
  mounted: function () {
    // console.log('ciao');
    this.refreshData();
    this.refreshInterval = setInterval(this.refreshData.bind(this), 10000);
    this.selectedTimestamp = this.roundWeek(Date.now());
    fetch("https://www.poliradio.it/api/getPalinsesto.php")
      .then((data) => data.json())
      .then((data) => (this.palinsesto = data));
    // const MS_IN_DAY = 24 * 60 * 60 * 1000;
    // const ts = Math.floor((Date.now() - 4 * MS_IN_DAY) / (7 * MS_IN_DAY));
    // this.selectedDate = `${
    //   new Date(ts * 7 * MS_IN_DAY + 11 * MS_IN_DAY).toISOString().split("T")[0]
    // }`;

    this.selectedDate = new Date().toISOString();
  },
  beforeUnmount: function () {
    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
  },
  computed: {
    curLocaleDate: function () {
      return new Date(this.selectedTimestamp).toLocaleDateString();
    },
    calendarStart: function () {
      return this.selectedTimestamp;
      // const MS_IN_WEEK = 7 * 24 * 60*60*1000;
      // let tmp = Date.parse(`${this.selectedDate}`);
      // console.log('start',`${this.selectedDate}`, tmp, new Date(Math.floor(tmp / MS_IN_WEEK) * MS_IN_WEEK));
      // return new Date(Math.floor(tmp / MS_IN_WEEK) * MS_IN_WEEK);
    },
    calendarEnd: function () {
      // const MS_IN_WEEK = 7 * 24 * 60*60*1000;
      // return new Date(new Date(this.calendarStart).getTime() + MS_IN_WEEK);
      return this.selectedTimestamp + 7 * 24 * 60 * 60 * 1000;
    },
    filteredEvents: function () {
      let activeRooms = this.rooms
        .filter(({ selected }) => selected == true)
        .map(({ value }) => value);

      const MS_IN_HOUR = 60 * 60 * 1000;
      const MS_IN_DAY = 24 * MS_IN_HOUR;

      let pal = this.palinsesto.map((item) => {
        const baseTs =
          this.roundWeek(this.selectedTimestamp) + item.day * MS_IN_DAY;

        // console.log(this.roundWeek(this.selectedTimestamp))
        const startDate = new Date(baseTs);
        startDate.setHours(item.hour);
        const endDate = new Date(startDate.getTime() + MS_IN_HOUR);

        return {
          name: `[ONAIR] ${item.name}`,
          resource: "room-palinsesto",
          timed: true,
          locked: true,
          approved: true,
          start: startDate.getTime(),
          end: endDate.getTime(),
        };
      });

      // console.log('PALINSESTO', pal)
      // let curTimestamp = Math.floor(this.selectedTimestamp / MS_IN_DAY)*MS_IN_DAY;
      // let pal = [];

      // for (let i = 0; i < 7; i++){
      //   pal = [...pal, ...this.palinsesto
      //     .filter(({day}) => ((i - 1 + curDate.getDay()) % 7) === day)
      //     .map(item => {
      //       return {
      //         name: `[ONAIR] ${item.name}`,
      //         resource: 'room-palinsesto',
      //         timed: true,
      //         locked: true,
      //         approved: true,
      //         start: new Date( baseTs),
      //         end: new Date(baseTs + MS_IN_HOUR),
      //       };
      //     })
      //   ];
      // }
      //       let pal = this.palinsesto.map(item => {
      //         const MS_IN_WEEK = 7*MS_IN_DAY;

      //         let startTimestamp = (new Date(curDate).setSeconds(0)) +

      //         startDate.setDate(curDate.getDay() + 1 + item.day);
      //         let startTimestamp = startDate.setHours(item.hour, 0, 0, 0);
      //         let endDate = new Date(startDate);
      //         let endTimestamp = endDate.setHours(item.hour + 1, 0, 0, 0);

      //       //  let  startDate1 = new Date(startDate);
      //       //  let  startDate2 = new Date(startDate);
      //       //  let  startDate3 = new Date(startDate);

      //       //  let  endDate1 = new Date(endDate);
      //       //  let  endDate2 = new Date(endDate);
      //       //  let  endDate3 = new Date(endDate);

      //       //  startDate1.setDate(startDate.getDate()-7);
      //       //  startDate2.setDate(startDate.getDate()+0);
      //       //  startDate3.setDate(startDate.getDate()+7);

      //       //  endDate1.setDate(endDate.getDate()-7);
      //       //  endDate2.setDate(endDate.getDate()+0);
      //       //  endDate3.setDate(endDate.getDate()+7);

      // const base = {
      //           name: `[ONAIR] ${item.name}`,
      //           resource: 'room-palinsesto',
      //           timed: true,
      //           locked: true,
      //           approved: true};

      //         return ([{
      //           start: startTimestamp - MS_IN_WEEK,
      //           end: endTimestamp - MS_IN_WEEK,
      //           ...base,
      //         },
      //         {
      //           start: startTimestamp,
      //           end: endTimestamp,
      //           ...base,
      //         }, {
      //           start: startTimestamp + MS_IN_WEEK,
      //           end: endTimestamp + MS_IN_WEEK,
      //           ...base,
      //         }
      //       ])
      //     })
      //     .flat();

      let toReturn = [...this.events, ...pal, ...this.eventPlaceholders]
        .filter(({ resource }) => activeRooms.indexOf(resource) !== -1)
        .map((item) => ({
          ...item,
          color: this.getRoomColor(item.resource, item.approved != "1"),
        }));

      return toReturn;
    },
  },
  methods: {
    logout() {
      ApiService.logout("calendario");
    },
    dateDiff(days) {
      const currDate = new Date(this.selectedTimestamp);
      currDate.setDate(currDate.getDate() + days);
      return currDate.getTime();
    },
    loadNextWeek() {
      this.selectedTimestamp = this.dateDiff(+7);
    },
    loadPrevWeek() {
      this.selectedTimestamp = this.dateDiff(-7);
    },
    createEventPlaceholder(data) {
      const start_date = Math.floor(
        Math.floor(Date.parse(`${data.date}T${data.time}:00`) / 1800000) *
          1800000
      );
      const end_date = start_date + 3600000;

      this.eventPlaceholders = this.rooms.map(({ value }) => ({
        start: start_date,
        end: end_date,
        name: "Nuovo Evento",
        allDay: "0",
        timed: true,
        resource: value,
        isPlaceholder: true,
      }));
    },
    enableRoom(data) {
      this.rooms = this.rooms.map((item) => ({
        ...item,
        selected: item.value == data ? !item.selected : item.selected,
      }));
    },
    createEvent(data) {
      this.newEventDate = `${data.date}T${data.time}`;
      this.id = null;
      this.updateEventOpen = true;
    },
    openEventInfo(data) {
      const MS_IN_MINUTES = 60 * 1000;

      if (data.event.isPlaceholder) {
        if (data.event.resource === "room-palinsesto") {
          alert("Impossibile Modificare il Palinsesto");
          return;
        }

        if (
          ApiService.parseJwt().permissions.indexOf("DIRETTORE_PROGRAMMI") ===
            -1 &&
          data.event.resource === "room-pills"
        ) {
          alert("Impossibile Modificare Pills");
          return;
        }

        const title = prompt("Inserisci Titolo Evento");

        const duration = prompt("Inserisci durata (30/60)");
        const ALLOWED_DURATIONS = ["30", "60"];
        if (ALLOWED_DURATIONS.indexOf(duration) === -1) {
          alert("La durata selezionata non e' disponibile");
          return;
        }

        if (title) {
          ApiService.send("calendario", "add", {
            title: title,
            start: data.event.start,
            end: data.event.start + parseInt(duration) * MS_IN_MINUTES,
            // desc: this.desc,
            resource: data.event.resource,
          }).then(this.refreshData);
        }
      } else if (!data.event.locked) {
        // console.log(data);
        this.selectedEvent = {
          ...data.event,
          resource: this.rooms.filter(
            ({ value }) => data.event.resource == value
          )[0]["text"],
        };
        this.updateEventOpen = true;
        // this.updateEventOpen = true;
        // this.selectedEvent = data.event.id;
        // this.newEventDate = null;
      }
    },
    closeEventInfo() {
      this.updateEventOpen = false;
    },
    openSelectDateModal() {
      this.selectDateOpen = true;
    },
    closeSelectDateModal() {
      this.selectDateOpen = false;
    },
    getRoomColor(room, dim) {
      let tmp = this.rooms
        .filter(({ value }) => value === room)
        .map(({ color }) => color)
        .reduce((acc, item) => item, "0,0,0");

      return `rgba(${tmp},${dim ? 0.5 : 1})`;
    },
    refreshData() {
      this.loading = true;
      ApiService.send("calendario", "list", {})
        .then(
          (data) =>
            (this.events = data
              .filter((item) => item.deleted == 0)
              .map((item) => ({
                ...item,
                start: parseInt(item.start),
                end: parseInt(item.end),
                name: `${item.title}`,
                timed: true,
              })))
        )
        .then(() => (this.loading = false));
      // .then(() => console.log(this.events));
    },
    roundDay(data) {
      const MS_IN_HOUR = 60 * 60 * 1000;
      const MS_IN_DAY = 24 * MS_IN_HOUR;
      return Math.floor(((data - MS_IN_HOUR) / MS_IN_DAY) * MS_IN_DAY);
    },
    //https://stackoverflow.com/questions/4156434/javascript-get-the-first-day-of-the-week-from-current-date
    roundWeek(d) {
      // console.log("rounding", d);
      // const MS_IN_HOUR = 60 * 60 * 1000;
      // const MS_IN_DAY = 24 * MS_IN_HOUR;
      // const MS_IN_DAY = 24*60*60*1000;
      // const MS_IN_WEEK = 7*MS_IN_DAY;
      // return ((Math.floor((data - 37 )) / MS_IN_WEEK )) * MS_IN_WEEK ;
      d = new Date(d);
      d.setHours(0, 0, 0);
      return new Date(
        d.setDate(d.getDate() - ((d.getDay() + 6) % 7))
      ).getTime();

      // let day = d.getDay();
      // let diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
      // d.setHours(0,0,0);
      // console.log(day, diff, d)
      // return new Date(d.getTime() - day * MS_IN_DAY).getTime();
      // return new Date(d.setDate(diff)).getTime();
    },
  },
  watch: {
    updateEventOpen: function () {
      if (this.updateEventOpen == false) {
        this.selectedEvent = { id: -1 };
        this.refreshData();
      }
    },
  },
};
</script>
