import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import VueRouter from "vue-router";

Vue.config.productionTip = false;

import ApiService from "./api.service.class";

ApiService.extractJwtUrl();

new Vue({
  vuetify,
  VueRouter,
  render: (h) => h(App),
}).$mount("#app");
