<template>
<v-app>
  <!-- <v-navigation-drawer app>
    
  </v-navigation-drawer> -->

  <!-- <v-app-bar app>
    
  </v-app-bar> -->
      <login></login>

  <!-- Sizes your content based upon application components -->
  <v-main>

    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <!-- If using vue-router -->
      <router-view></router-view>
      <calendar></calendar>
    </v-container>
  </v-main>
</v-app>
</template>

<script>
import Calendar from './components/Calendar.vue';
import Login from './components/Login.vue';
// import HelloWorld from './components/HelloWorld';

export default {
  name: 'App',

  components: {
    Calendar,
    Login
    
  },

  data: () => ({
    //
  }),
};
</script>