<template>
  <div>
    <v-dialog v-model="showModal" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Logged Out </v-card-title>
        <v-card-text
          >Please Login Again<br />
          <a :href="url" target="_blank">LogIn</a></v-card-text
        >
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
// let recheckTimer = null;
import ApiService from "../api.service.class";

export default {
  name: "Login",
  data: () => ({
    url: null,
  }),
  created() {
    ApiService.showLoginForm = (url) => (this.url = url);
    ApiService.hideLoginForm = () => (this.url = null);
  },
  computed: {
    showModal() {
      return this.url !== null;
    },
  },
  // created: function () {
  //   this.extractJwtUrl();
  //   recheckTimer = setInterval(this.checkSavedJwt, 1000);
  //   this.checkSavedJwt();
  // },
  // beforeDestroy: () => {
  //   clearInterval(recheckTimer);
  // },
  // methods: {
  //   checkSavedJwt() {
  //     try {
  //       let parsed_jwt = JSON.parse(localStorage.getItem("_JWT_parsed"));
  //       if (parsed_jwt.exp <= Date.now() / 1000) {
  //         throw "Expired JWT";
  //       }
  //       this.loggedOut = false;
  //     } catch (e) {
  //       console.log(e);

  //       this.loggedOut = true;
  //       localStorage.removeItem("_JWT_parsed");
  //       localStorage.removeItem("_JWT");
  //     }
  //   },
  //   extractJwtUrl() {
  //     const hash = window.location.hash;
  //     const prefix = "#jwt=";
  //     if (hash.startsWith(prefix)) {
  //       const jwt = hash.replace(prefix, "");
  //       localStorage.setItem("_JWT", jwt);
  //       const parsed_jwt = atob(jwt.split(".")[1]);
  //       localStorage.setItem("_JWT_parsed", parsed_jwt);
  //       window.location.hash = "";
  //     }
  //   },
  // },
};
</script>
