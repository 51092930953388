<template>
  <!-- <v-card
    > -->
  <v-card>
    <v-card-title
      ><span>{{ parsedStartDate }}</span
      >
    </v-card-title>
    <v-card-text
      ><span><v-chip class="ma-2" :color="color">{{ room }} </v-chip>{{ title }}</span></v-card-text
    >
    <v-card-actions v-if="clean">
      <v-btn class="ma-2" @click="approve()"> Approve</v-btn>
      <v-btn class="ma-2" @click="reject()"> Reject </v-btn>
    </v-card-actions>
    <!--         
        <span>{{parsedEndDate}}</span> -->
    <!--         
        <v-row
          ><select v-model="resource"
            ><option value="">Seleziona Studio</option
            ><option value="room-studio1">Studio1</option
            ><option value="room-studio2">Studio2</option></select
          ></v-row -->
    <!-- > -->
    <!-- <v-row>
          <v-col cols="12" md="6">
            <input type="datetime-local" v-model="start" required /> </v-col
          ><v-col cols="12" md="6">
            <input type="datetime-local" v-model="end" required />
          </v-col>
        </v-row> -->
    <!-- <v-row>
          <textarea v-model="desc" placeholder="Descrizione Evento" />
        </v-row>
        <v-btn v-if="isNewEvent" class="ma-2" @click="create()">
          Create
        </v-btn>
        <v-btn v-if="isOldEvent" class="ma-2" @click="approve()">
          Approve
        </v-btn>
        <v-btn v-if="isOldEvent" class="ma-2" @click="reject()">
          Reject
        </v-btn>
        <v-btn v-if="isOldEvent" class="ma-2" @click="del()">
          Delete
        </v-btn> -->



    <!-- 
             <v-col cols="12" md="4">
              <v-text-field
                v-model="lastname"
                :rules="nameRules"
                :counter="10"
                label="Last name"
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                required
              ></v-text-field>
            </v-col>
          </v-row> -->
  </v-card>
  <!-- </v-card
  > -->
</template>
<script>
import ApiService from "../api.service.class";
// import DatetimeSelector from "./DatetimeSelector.vue";

// function refreshEventData() {
  // console.log(this.props);
  // this.resetState();
  // if (this.id) {
  //   this.loading = true;
  //   ApiService.send("calendario", "get", { id: this.id }).then((data_) => {
  //     const data = data_[0];
  //     if (data) {
  //       const { title, desc, start, end, id } = data;
  //       this.title = title;
  //       this.desc = desc;
  //       this.id = id;
  //       console.log(start);
  //       const _start = new Date(parseInt(start));
  //       const _end = new Date(parseInt(end));
  //       this.start = _start.toISOString().replace("Z", "");
  //       this.end = _end.toISOString().replace("Z", "");
  //     }
  //     this.loading = false;
  //   });
  // }
// }
export default {
  name: "EventEditor",
  props: ["id", "title", "color", "room", "start", "end"],
  data: () => ({
    clean: true
    // title: "",
    // start: (this.props.start?this.props.start:null),
    // desc: "",
    // end: null,
    // loading: false,
    // resource: "",
  }),
  computed: {
    parsedStartDate() {
      // console.log(this.start);
      // console.log(new Date(parseInt(this.start)));
      const tmp = new Date(parseInt(this.start));
      return `${tmp.toLocaleDateString()} - ${this.pad(tmp.getHours(),"0",2)}:${this.pad(tmp.getMinutes(),"0",2)}`;
    },
    // parsedEndDate(){
    //  return new Date(parseInt(this.end)).toISOString()
    // },
    // isNewEvent() {
    //   return this.id === null;
    // },
    // isOldEvent() {
    //   return !this.isNewEvent;
    // },
  },
  methods: {
    pad(data, padding, size) {
      data = `${data}`;
      // return data.length;
      while (data.length < size) {
        data = `${padding}${data}`;
      }
      return data;
    },
    // resetState() {
    //   this.title = "";
    //   this.start = (this.props.start?this.props.start:null);
    //   this.desc = "";
    //   this.end = null;
    // },
    // create() {
    //   this.loading = true;
    //   const _start = new Date(this.start);
    //   const _end = new Date(this.end);
    //   ApiService.send("calendario", "add", {
    //     title: this.title,
    //     start: _start.getTime(),
    //     end: _end.getTime(),
    //     desc: this.desc,
    //     resource: this.resource,
    //   }).then(() => {
    //     this.loading = false;

    //     this.resetState();
    //   });
    // },
    approve() {
      this.loading = true;
      ApiService.send("calendario", "accept", { id: this.id }).then(() => {
        this.clean = false;
      });
    },
    reject() {
      this.loading = true;
      ApiService.send("calendario", "reject", { id: this.id }).then(() => {
        this.clean = false;
      });
    },
  },
  watch: {
    id: function(){
     // this.clean = this.approved == '0' && this.deleted == '0';
     this.clean = true;
    }
  }
};
</script>
